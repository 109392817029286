<template>
  <div class="navbar-container ">
    <!-- Nav Menu Toggler -->



    <b-navbar-nav class="d-flex justify-content-evenly ml-auto">
      <div class="d-flex align-items-center  justify-content-start">
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item">
            <b-link class="nav-link" @click="toggleVerticalMenuActive">
              <feather-icon icon="MenuIcon" size="21" />
            </b-link>
          </li>
        </ul>
        <h6>
          <span v-if="packCount > 0">
            Félicitations ! {{ packCount }} parcours de formation déjà gagné(s) !
          </span>
          Plus que {{3 - points }} <span v-if=" 3 - points == 1">contrat</span> <span v-else>contrats</span> pour gagner
          un de nos parcours de formation DDA
          <b-button @click="redirectToFormationDda" variant="link">(En savoir plus)</b-button>
        </h6>

      </div>
      <div class="d-flex align-items-center justify-content-end bd-highlight ml-auto">

        <b-button v-if="!isOnTarification && (currentUser.type_utilisateur != 'assureur')" @click="ToTarification"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" class="mr-2">
          <feather-icon v-if="prospectDataFromFicheTier" icon="PlusIcon" />
          <span class="align-middle text-uppercase"> Réaliser un devis</span>
        </b-button>

        <notification-dropdown v-if="currentUser.type_utilisateur != 'assureur'" />
        <user-dropdown />

      </div>
    </b-navbar-nav>

    <!-- <button @click="showcelebrete">Celebrate</button> -->

  </div>
</template>


<script>
import { BLink, BNavbarNav, BButton } from 'bootstrap-vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import Ripple from 'vue-ripple-directive'
import Pusher from 'pusher-js';
import environnement from '@/shared/api/globalParametres'
import Echo from 'laravel-echo';




export default {
  components: {
    BLink,
    BNavbarNav,
    NotificationDropdown,
    UserDropdown,
    BButton
  },
  data() {
    return {
      currentUser: null,
      points: 0,
      packCount: null,
    }
  },
  directives: {
    Ripple
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    prospectDataFromFicheTier: {
      type: Object,
      default: null,
      required: false
    },
  },
  created() {
    this.getCurrentUser()
    this.fetchPackFormationDda()

    window.Pusher = Pusher
      // This assumes you have already saved your bearer token in your browsers local storage

      //prod config

      // window.Echo = new Echo({
      //   broadcaster: 'pusher',
      //   key: 'bubbleinkey',
      //   cluster: 'mt1',
      //   wsHost: '127.0.0.1', 
      //   authEndpoint: 'http://127.0.0.1/api/broadcasting/auth',
      //   wsPort: 6001,
      //   wssPort: 6001,
      //   disableStats: true,
      //   forceTLS: false,
      //   encrypted: true,
      //   enabledTransports: ['ws', 'wss'],
      //   auth: {
      //     headers: {
      //         Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      //         Accept: 'application/json',
      //         'X-Tenant': 'my-tenant',
      //     },
      //   },
      // })

      //end prod config
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'bubbleinkey',
        cluster: 'mt1',
        wsHost: environnement.wsHost,
        authEndpoint: 'https://'+environnement.wsHost+'/api/broadcasting/auth',
        wsPort: environnement.socketPort,
        wssPort: environnement.socketPort,
        disableStats: true,
        forceTLS: true,
        encrypted: true,
        auth: {
          headers: {
              Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
              Accept: 'application/json',
              'X-Tenant': 'my-tenant',
          },
        },
      })

      

    window.Echo.private('notificationchannel.'+this.currentUser.id)
        .listen('NotificationEvent', (data) => {
          //this.messageToast(data.response.message, 'Produit', 'success', 'BellIcon')
          this.fetchPackFormationDda()
    })
    window.Echo.channel('test-websocket')
        .listen('TestWebsocket', (data) => {
          console.log(data)
    })


  },
  methods: {
    celebrate() {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    },
    showcelebrete(){
      for (let i = 0; i < 10; i++) {
        setTimeout(() => {
          this.celebrate();
        }, (i + 1) * 500);
      }
    },
    showEffet(total_pack_formation, showEffet){
      if(showEffet){// show by base
        this.showcelebrete()
      }
      console.log(this.packCount)
      console.log(total_pack_formation)

      if(this.packCount !=  null && this.packCount != total_pack_formation ){ // show in real time 
        console.log('111');
        this.showcelebrete()
      }

    },
    fetchPackFormationDda() {
      this.$http.get(`/courtier/fetchPackFormation`)
        .then((res) => {
          this.showEffet(res.data.data.total_foramtion_valide, res.data.data.show_effet)
          this.points = res.data.data.points;
          this.packCount = res.data.data.total_foramtion_valide;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ToTarification() {
      this.$router.push('/quickwins/tarification');
    },
    getCurrentUser() {
      this.currentUser = JSON.parse(localStorage.getItem("userData"));
    },
    redirectToFormationDda() {
      this.$router.push({ name: 'catalogues-list' ,params: { formation_dda: "formation-dda" }});
    },
  },
  computed: {
    isOnTarification() {
      return (this.$route.path === '/quickwins/tarification' ||
        this.$route.name === 'projets-proposition' ||
        this.$route.name === 'projets-souscription');
    }
  },
}
</script>

<style>
</style>
